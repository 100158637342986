import React, {useEffect, useState} from "react"
import Navbar from "../../components/StudiLive Comps/navbar"
import StudiLiveFooter from "../../components/StudiLive Comps/footer"
import StudiLiveMobileFooter from "../../components/StudiLive Comps/mobileFooter"
import MobileNavbar from "../../components/StudiLive Comps/mobileNavbar.js"

import "../../css/studilive/globalRules.css"
import "../../css/globals.css"
import FormSupport from "../../components/StudiLive Comps/formSupport"
import useIsMobile from "../../hooks/useIsMobile"
import DatalayerEvents from "../../../services/datalayer"

export default function ContactUs(){
  const isMobile = useIsMobile('1023px');

  const [datalayerEvents, setdatalayerEvents] = useState(new DatalayerEvents())

  useEffect(() => {
    datalayerEvents.init();
  }, [])

  return (
    <div>
      <div>
        <div className="pageBanner-studilive supportPage bannerText">
          <h1 className="displayNone">Contact Us</h1>
          <h2>Support - Contact Us | Studi Live</h2>
        </div>
      </div>
      {isMobile ? <MobileNavbar /> : <Navbar />}

      <FormSupport />

      {isMobile ? <StudiLiveMobileFooter /> : <StudiLiveFooter />}
    </div>
  )
}
