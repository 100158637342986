import React from "react"
import CallImage from "../../images/call-icon.png"
import MailImage from "../../images/email-icon.png"
import apiconfig from "../../../config/apiconfig"
import sfdcConfig from "../../../config/sfdcConfig"
import { AlertMessage } from "../alert"

class FormSupport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: 0,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.formValidation = this.formValidation.bind(this)
    this.sendOTP = this.sendOTP.bind(this)
  }

  formValidation = function (fullName, email, contact, grade, otp) {
    let nameRegex = new RegExp(/^[a-zA-Z ]*$/)
    let emailRegex = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
    let contactRegex = new RegExp(/^[6-9]\d{9}$/)
    let otpRegex = new RegExp(/^\d{4}$/)
    let flag = false

    if (
      fullName &&
      nameRegex.test(fullName) &&
      email &&
      emailRegex.test(email) &&
      contact &&
      contactRegex.test(contact) &&
      otp &&
      otpRegex.test(otp) &&
      grade
    ) {
      flag = true
    }
    return flag
  }

  sendOTP = function () {
    let inputMobile = document.getElementById("contact_us_mobile")
    if (!inputMobile.checkValidity()) {
      inputMobile.reportValidity()
      return
    }
    let mob = document.getElementById("contact_us_mobile").value
    let otpHeaders = new Headers()
    otpHeaders.append("Content-Type", "application/x-www-form-urlencoded")

    let otpurlencoded = new URLSearchParams()
    otpurlencoded.append("action", "send_otp")
    otpurlencoded.append("contact_no", mob)
    otpurlencoded.append("pagetype", "StudiLive")
    
    fetch(apiconfig.OTPAPI, {
      method: "POST",
      headers: otpHeaders,
      body: otpurlencoded,
      redirect: "follow",
    })
      .then(response => response.json())
      .then(result => {
        document.getElementById("clear").style.visibility = "hidden"
        setTimeout(() => {
          document.getElementById("clear").style.visibility = "visible"
        }, 20000)
      })
      .catch(error => console.log("error", error))
  }

  handleSubmit = function (event) {
    event.preventDefault()
    const data = new FormData(event.target)
    if (data.get("radioBtnMob")) {
      let fullName = data.get("fullname")
      let email = data.get("email")
      let contact = data.get("mobile")
      let grade = data.get("grade")
      let otp = data.get("otp")
      let time = data.get("time")
      let message = data.get("message")

      if (this.formValidation(fullName, email, contact, grade, otp)) {
        // ----------------------------------------------

        let data = {
          txtname: fullName,
          txtemail: email,
          txtcontact: contact,
          btn_submit: "true",
          txtotp: otp,
          grade: "NA",
          time: "NA",
          message: message,
          source: "studilive",
        }

        var myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

        var urlencoded = new URLSearchParams()

        for (let key in data) {
          urlencoded.append(key, data[key])
        }

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow",
        }

        fetch(apiconfig.FORMSAPI, requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result["code"] == 200) {
              //USING THE AUTH TOKEN AND PUSHING LEAD TO SFDC
              let authToken = result["token"]

              var SFDCHeaders = new Headers()
              SFDCHeaders.append("Authorization", "Bearer " + authToken)
              SFDCHeaders.append("Content-Type", "application/json")

              let newData = [
                {
                  Lastname: fullName,
                  Email: email,
                  Phone: contact,
                  Grade: grade,
                  leadsource: "studilive",
                  description: message,
                  PreferredtimetoCall: time,
                },
              ]

              var userData = JSON.stringify({
                datalist: newData,
              })

              var SFDCrequestOptions = {
                method: "POST",
                headers: SFDCHeaders,
                body: userData,
                redirect: "follow",
                mode: "cors",
              }

              fetch(sfdcConfig.LEADURL, SFDCrequestOptions)
                .then(response => {
                  return { res: response.json(), status: response.status }
                })
                .then(result => {
                  if (result.status == 200) {
                    this.setState({ status: 200 })
                    document.getElementById("contactUsFormId").reset()
                  }
                })
                .catch(error => {
                  console.log(error)
                })
            } else if (result["code"] == 400) {
              this.setState({ status: 400 })
            } else {
              this.setState({ status: 500 })
            }
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        alert("Please fill all the fields accurately")
      }
    } else {
      alert("Please Accept the T&C")
    }
  }

  componentDidMount() {
    let radioState
    document
      .getElementsByClassName("imChecked")[0]
      .addEventListener("click", function (e) {
        if (radioState === this) {
          this.checked = false
          radioState = null
        } else {
          radioState = this
        }
      })
  }

  render() {
    return (
      <div id="contact_us">
        <div className="main_row_card_form_display">
          <div className="contact-card-container">
            <div className="contact-card-heading">
              <p>Contact Us</p>
            </div>
            <div className="contact-card-body-text">
              <p>
                Have any queries? Facing any issues?
                <br /> Get assistance.
              </p>
            </div>
            <div className="contact-us-card-row">
              <div className="threeCols col-md-4 col-sm-4">
                <div className="round-pic mail">
                  <img
                    src={MailImage}
                    alt="Tata Studi Email Icon"
                    title="Tata Studi Email Icon"
                  />
                </div>
                <p className="mail_us"> Mail Us </p>
                <div className="mail_link">
                  <a href="mailto:support@tatastudi.com">
                    support@tatastudi.com
                  </a>
                </div>
              </div>
              <div className="threeCols col-md-4 col-sm-4 ">
                <div className="round-pic">
                  <img
                    src={CallImage}
                    alt="Tata Studi Call Icon"
                    title="Tata Studi Call Icon"
                  />
                </div>
                <p className="call_us"> Call Us</p>
                <p className="number">7506617777</p>
              </div>
            </div>
          </div>

          <div className="request_call_back">
            <div>
              <span className="request_label">Request a Call</span>
            </div>
            <form id="contactUsFormId" onSubmit={this.handleSubmit}>
              <div className="request_content">
                <div className="col-lg-4 col-md-4 padding-0">
                  <input
                    type="text"
                    id="fname"
                    className="request_input"
                    name="fullname"
                    pattern="^[A-Za-z0-9 ]+"
                    title="Only alphabets and numbers are allowed"
                    placeholder="Full Name*"
                    maxLength="25"
                    required
                  />
                </div>
                <div className="col-lg-4 col-md-4 padding-0">
                  <input
                    type="email"
                    id="contact_us_email"
                    className="request_input"
                    name="email"
                    placeholder="Email Address*"
                    required
                  />
                </div>
                <div className="col-lg-4 col-md-4 padding-0">
                  <input
                    type="text"
                    id="contact_us_mobile"
                    className="request_input"
                    name="mobile"
                    placeholder="Mobile Number*"
                    pattern="^[6789].+[0-9]+"
                    title="Number should start with 6,7,8 or 9 and length 10"
                    minLength="10"
                    maxLength="10"
                    required
                  />
                  <button
                    type="button"
                    id="clear"
                    className="support_otp_btn"
                    onClick={this.sendOTP}
                  >
                    {" "}
                    &nbsp;Get Otp&nbsp;{" "}
                  </button>
                </div>
              </div>

              <div className="request_content_msg">


                <div className="col-md-3 padding-0">
                  <select
                    id="grade"
                    name="grade"
                    className="select_form select_desktop 
                    contact_form_grade_color"
                    required
                  >
                    <option value="" selected disabled hidden>
                      Grade{" "}
                    </option>
                    <option value="Class 8">Grade 8</option>
                    <option value="Class 9">Grade 9</option>
                    <option value="Class 10">Grade 10</option>
                  </select>
                </div>
                <div className="col-lg-6 col-md-6 padding-0">
                  <textarea
                    id="contact_us_message"
                    maxlength="1000"
                    className="request_input_msg"
                    name="message"
                    placeholder="Message*"
                    required
                  />
                </div>

                <div className="col-lg-3  col-md-3 padding-0">
                  <input
                    type="text"
                    id="otp"
                    className="request_input_otp"
                    name="otp"
                    minLength="4"
                    maxLength="4"
                    pattern="[0-9]+"
                    title="Please Enter correct OTP"
                    placeholder="OTP Verification*"
                    required
                  />
                </div>
              </div>

              <div class="form-check cf_radio_div">
                <input
                  class="form-check-input imChecked"
                  type="radio"
                  name="radioBtnMob"
                  id="i1"
                  required
                  title="Please select the T&Cs"
                />
                <label class="form-check-label cf_accept_label" for="i1">
                  I Accept the
                  <a
                    className="cf_accept_label"
                    id="terms"
                    href="/studilive/termsandconditions/"
                    target="_blank"
                  >
                    <span className="t_and_c">T&amp;Cs</span>
                  </a>
                </label>
              </div>

              <div>
                <input
                  type="submit"
                  className="request_send_btn"
                  value="Send"
                />
              </div>
            </form>
          </div>
        </div>
        <div className="form_custom_alert">
          {(() => {
            if (this.state.status == 200) {
              return (
                <AlertMessage
                  variant="alert-success"
                  message="Success!"
                  classes="col-xs-10 col-md-3"
                />
              )
            } else if (this.state.status == 400) {
              return (
                <AlertMessage
                  variant="alert-danger"
                  message="Some Error Occured! Please try again."
                  classes="col-xs-10 col-md-3"
                />
              )
            } else if (this.state.status == 500) {
              return (
                <AlertMessage
                  variant="alert-danger"
                  message="Not Verified, Please try again."
                  classes="col-xs-10 col-md-3"
                />
              )
            } else {
              return <></>
            }
          })()}
        </div>
      </div>
    )
  }
}

export default FormSupport
